import update from "immutability-helper";
import { useContext, useEffect, useState } from "react";
import { MoveCard } from "./MoveCard";
import { languages } from "../../../App";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";

const AdminDashboard = () => {
  let curLanguage = useContext(languages);
  let courseinfo = localStorage.getItem("courseDetails");
  const courseinfoObject = JSON.parse(courseinfo);
  const [open, setOpen] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [customModule, setCustomModule] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept`,
          {
            department: userInfo[0].department,
            schoolcode: userInfo[0]?.institution,
          }
        );

        const customModules = response.data.config.custommodules;
        const departmentKey = userInfo[0].department.toLowerCase();
        const departmentConfig = customModules?.[departmentKey] || {};
        setCustomModule(departmentConfig);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    const createCard = (
      id,
      text,
      links,
      iconPath,
      customclass,
      width = 110
    ) => ({
      id,
      text,
      links,
      icons: (
        <img
          src={`${
            process.env.REACT_APP_wsPrefix
          }/lms-assets/icons/${iconPath}.${
            iconPath === "module" ? "png" : "svg"
          }`}
          alt={text.toLowerCase()}
          width={width}
        />
      ),
      customclass,
    });

    let baseCards = [
    
      createCard(
        1,
        curLanguage.attendance || "Attendance",
        "/adminAttendance",
        "attendance",
        "orange",
        75
      ),
      createCard(2, "Planner", "/Planner", "planner", "yellow", 120),
      createCard(
        3,
        curLanguage.certificate || "Certificate",
        "/addCertificate",
        "certificate",
        "brown",
        120
      ),
      createCard(
        4,
        curLanguage.calendar || "Calendar",
        "/calendar",
        "calendar",
        "purple",
        120
      ),
      createCard(5, "Fee", "/feeCollection", "fees", "red", 75),
      createCard(
        6,
        curLanguage.timeTable || "Time Table",
        "/TimeTable",
        "timetable",
        "blue",
        120
      ),
      createCard(7, "Setting", "/setting", "usermanagement", "darkblue", 120),
      createCard(8, "Module", "/module", "module", "lightblue", 120),
    ];
console.log(customModule, "customModule")
    if (Object.keys(customModule).length > 0) {
      const customCards = Object.keys(customModule).map((presentData, index) =>
        createCard(
          index + 1,
          customModule[presentData].type,
          `/admissionForm?type=${customModule[presentData].type}`,
          "admission",
          "green",
          110
        )
      );
      setCards([...customCards, ...baseCards]);
    } else {
      setCards(baseCards);
    }
  }, [customModule]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const moveCard = (dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  };

  const renderCard = (card, index) => {
    return (
      <MoveCard
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        clickHand={card.clickHandler}
        links={card.links}
        moveCard={moveCard}
        icons={card.icons}
        customclass={card.customclass}
      />
    );
  };

  return (
    <>
      <Grid container spacing={3} mb={2} sx={{ justifyContent: "center" }}>
        {cards.map((card, i) => renderCard(card, i))}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            backgroundColor: "popup.titleHeaderColor",
            color: "popup.popupheadertextcolor",
            mb: 4,
          }}
        >
          {curLanguage.attendance}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
          className="adminInSec"
        >
          {courseinfoObject?.map((course) => {
            return (
              <Link
                rel="preload"
                key={course.id}
                to={"/attendance"}
                className="adminInList"
                state={{
                  id: course.id,
                  fullname: course.fullname,
                }}
              >
                <ListItemText
                  value={course.fullname}
                  sx={{ color: "#fff" }}
                  divider={true}
                  dense={true}
                  className="listHead"
                >
                  <div>
                    <img
                      src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/attendance2.svg`}
                      alt="attendanceIcon"
                      width={60}
                    />
                  </div>
                  {course.fullname}
                </ListItemText>
              </Link>
            );
          })}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminDashboard;
