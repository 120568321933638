import axios from "axios";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import PrincipalFilter from "../../../_Components/Common/Filter/PrincipalFilter";
import { useEffect, useState } from "react";
import { Box, Card, Container } from "@mui/material";
import StudentRecord from "../../Attendance/StudentRecord";
import DownloadAllAttendance from "../../Attendance/DownloadAllAttendance";

export default function StaffAttendance() {
  const nToken = localStorage.getItem("nToken");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYr = localStorage.getItem("AY");
  const [attendanceData, setAttendanceData] = useState(null);
  const [attendanceLabel, setAttendanceLabel] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [filterDepartmentData, setFilterDepartmentData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [selectedMonthVal, setSelectedMonthVal] = useState(
    new Date().getMonth() + 1
  );

  // Get student info for filtering purposes
  const handleFilter = (stdclass, stdsection, stdsubject, department) => {
    fetchData(department, stdsubject, stdsection, stdclass);
    setFilterDepartmentData(department);
    setFilterValue(department, stdsubject, stdsection, stdclass);
  };

  const fetchData = async (
    filterDepartment,
    filterSubject,
    filterSection,
    filterClass
  ) => {
    try {
      const enroledURL = `${process.env.REACT_APP_nodewsPrefix}/FetchUsers`;
      const response = await axios.post(
        enroledURL,
        {
          department: filterDepartment,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );

      if (filterDepartment === "student") {
        const studentData = response.data.records.filter((val) => {
          // Check if the department is student and institution matches
          const isStudent =
            val.department?.toLowerCase() === "student" &&
            val.institution === userinfo[0].institution;
          // If no class or section is selected, show all students
          if (!filterClass && !filterSection) {
            return isStudent;
          }
          // If only class is selected, filter students of that class
          if (filterClass && !filterSection) {
            return isStudent && val.class === filterClass;
          }
          // If both class and section are selected, filter students of that class & section
          if (filterClass && filterSection) {
            return (
              isStudent &&
              val.class === filterClass &&
              val.section === filterSection
            );
          }
          // If section is selected without class, filter students of that section
          if (!filterClass && filterSection) {
            return isStudent && val.section === filterSection;
          }
          return false;
        });
        setStudentInfo(studentData);
      } else if (filterDepartment === "Pedagogy") {
        const studentData = response.data.records.filter(
          (val) =>
            val.department?.toLowerCase() === "pedagogy" &&
            val.institution === userinfo[0].institution
        );
        setStudentInfo(studentData);
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };
  
  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const studentIds = studentInfo?.map((data) => data.id);
        const attendanceUrl = `${process.env.REACT_APP_nodewsPrefix}/viewAttendance`;
        const attendResponse = await axios.post(
          attendanceUrl,
          {
            student_id: studentIds,
            class_id: "",
            section_id: "",
            financial_year: financialYr,
            day: "",
            month: "",
            schoolcode: userinfo[0].institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        const updatedAttendanceData = studentIds.reduce(
          (content, studentId) => {
            content[studentId] = attendResponse.data.attendance_data[studentId];
            return content;
          },
          {}
        );
        setAttendanceData(updatedAttendanceData);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };
    fetchAttendanceData();

    const fetchAttendanceData2 = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept`,
          {
            schoolcode: userinfo[0]?.institution,
            department: userinfo[0]?.department,
          }
        );
        setAttendanceLabel(response.data.config.attendance ? response.data.config.attendance : response.data.defaultConfig.attendance);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchAttendanceData2();
  }, [studentInfo, filterDepartmentData]);

  const selectedMonthData = (data) => {
    setSelectedMonthVal(data);
  };


  return (
    <>
      <MainCard cardTitle="Attendance">
        <PrincipalFilter
          forDepartment
          forClass
          forSection
          onselectionchangeData={handleFilter}
        />
      </MainCard>
      <Container maxWidth="xl">
        {filterDepartmentData && (
          <Card
            sx={{
              backgroundColor: "cardColor.main",
              color: "cardColor.contrast",
              position: "relative",
              marginTop: "16px",
              padding: "10px 0 ",
            }}
            elevation={5}
            className="customCard"
          >
            {studentInfo && (
              <>
                <Box
                  className="downloadIcon"
                  sx={{ position: "absolute", right: 0, top: 0 }}
                >
                  <DownloadAllAttendance
                    studentInfo={studentInfo}
                    attendanceinfo={attendanceData}
                    selectedMonthVal={selectedMonthVal}
                  />
                </Box>

                <StudentRecord
                  studentInfo={studentInfo}
                  attendanceData={attendanceData}
                  selectedMonth={selectedMonthData}
                  attendanceLabel={attendanceLabel}
                />
              </>
            )}
          </Card>
        )}
      </Container>
    </>
  );
}
