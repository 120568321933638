import axios from "axios";
import React, { useContext, useState } from "react";
import { languages } from "../../App";
import "./QuizAttemptSummary.scss";
import {Box,Card,Container,Typography,} from "@mui/material";
import {useSearchParams } from "react-router-dom";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import QuizSummaryList from "./QuizSummaryList";
import Loader from "../../_Components/Utils/loader/Loader";
const QuizAttemptSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [quizSubmited, setQuizSubmited] = useState(false);

  const token = localStorage.getItem("token");
  //const quizID = stateData.state.quizID;

  const [SearchParams] = useSearchParams();

  const attemptId = SearchParams.get("id");
  let isTimeup = SearchParams.get("isTimeup");
  console.log(isTimeup);

  

  const submitAttemptURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_process_attempt&moodlewsrestformat=json&attemptid=${attemptId}&finishattempt=1`;  

  const sumbitHandler = () => {
    setIsLoading(true);
    setQuizSubmited(true);
    console.log(submitAttemptURL);
    axios.get(submitAttemptURL).then((response) => {
      console.log(response.data, "submitresult");
      //navigation("/GetCourseContent/modAccessInformation",{state:{id: quizID}})
      setIsLoading(false);
    });
  };



  let quizSubmitContent = ``;

  if (quizSubmited) {
    if (isLoading) {
      quizSubmitContent = <Loader />;
    } else {
      quizSubmitContent = "Quiz Submitted Successfully";
    }
  }
  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard attemptCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main" }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>Assessment</b>
          </Box>
        </Typography>
        <Box className="customCardContent" p={2}>
          <Typography variant="h6" py={1}>
            Attempts Summary
          </Typography>

          {console.log(quizSubmitContent, "qsc")}
          {quizSubmitContent}


          {!quizSubmited && (
            <QuizSummaryList
              attId={attemptId}
              sumbitHandler={sumbitHandler}
              isLoading={isLoading}
            />
          )}
        </Box>
      </Card>
    </Container>
  );
};
export default QuizAttemptSummary;
