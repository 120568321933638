import axios from "axios";
import "./assessmentAttempt.css";
import { useLocation, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { Box} from "@mui/system";
import QuizQuestions from "../Quiz/QuizQuestions";
import { languages } from "../../App";
import MainCard from "../../_Components/Common/Cards/MainCard";
import Loader from "../../_Components/Utils/loader/Loader";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const answerFlaged = [];

function AssessmentAttempt() {
  const [SearchParams] = useSearchParams();

  const attemptId = SearchParams.get("id");
  const quizID = SearchParams.get("quizId");
  const totalTime = SearchParams.get("totalTime");
  const pageNotoGO = SearchParams.get("pageNo");

  const location = useLocation();
  const token = localStorage.getItem("token");
  let curLanguage = useContext(languages);

  const [quesData, setquesData] = useState();

  console.log(location.state, "ssss")

  console.log("checking1.0: pageto go", pageNotoGO)
  console.log("checking1.0: stateData", location.state);

  

  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setpageNo] = useState(0);
  const [studentAnswers, setUserAnswers] = useState([]);
  const [flagClicked, setFlagClicked] = useState(0);
  let questDisplay = {};

  const [parentState, setParentState] = useState(0);

  const handleChildAction = (rerender) => {
    // Update parent state to trigger re-render
    setParentState(rerender);
  };
  
  /* 

  console.log(location.state.quizSummarycheck, "ind qs")
  if (location.state.quizSummarycheck === "yes") {
    attemptId = location.state.attemptid;
  } else {
    attemptId = location.state.id;
  } */

  console.log(attemptId, "attempt id111", location.state)

  const getQuestionData = async (url) => {
    setIsLoading(true);
    await axios.get(url).then((response) => {
      console.log("INDERv1", response.data, url)
      setquesData(response.data);
    }).catch((error) => {
      console.log(error.message, "Error1")
    });
    setIsLoading(false);
  };

  let quesHTMLDataURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_attempt_data_info&moodlewsrestformat=json&attemptid=${attemptId}&page=${0}`;

  useEffect(() => {
    if (location.state?.quizSummarycheck) {
      setpageNo(location.state.page);
    }
  }, [location.state?.quizSummarycheck]);

  useEffect(() => {
    console.log("INDERv1 useeffect", quesHTMLDataURL)
    getQuestionData(quesHTMLDataURL);
  }, []);

  const nextQuestion = () => {
    if (pageNo + 1 < questDisplay.noOfQuestion) {
      setpageNo((prevState) => prevState + 1);
    }
  };

  const previousQuestion = () => {
    if (pageNo > 0) {
      setpageNo((prevState) => prevState - 1);
    }
  };

  const flagQuestion = () => {
    getQuestionData(quesHTMLDataURL);
    //setFlagClicked(prev => prev + 1);
  };
  console.log(quesData, "questData111");


  if (quesData && quesData.hasOwnProperty("errorcode")) {
    questDisplay.error = quesData.message
  } else if (quesData) {
    
    questDisplay.quizID = quizID;
    questDisplay.assesmentId = attemptId;
    questDisplay.noOfQuestion = quesData.questions.length;
    questDisplay.questionType = quesData.questions[pageNo].type;
    questDisplay.questionText = JSON.parse(
      quesData.questions[pageNo].html
    ).questiontext;
    questDisplay.answer = JSON.parse(quesData.questions[pageNo].html).answers_data;
    questDisplay.attempt = quesData.questions[pageNo].status;
    questDisplay.qid = JSON.parse(quesData.questions[pageNo].html).question_id;
    questDisplay.qNo = pageNo;
    questDisplay.previousQuestion = previousQuestion;
    questDisplay.nextQuestion = nextQuestion;
    questDisplay.pageNo = pageNo;
    questDisplay.setPageNo = setpageNo;
    questDisplay.answerFlaged = quesData.questions[pageNo].flagged; //answerFlaged;
    questDisplay.answerAttempt = JSON.parse(
      quesData.questions[pageNo].html
    ).answer_response;
    questDisplay.formElements = JSON.parse(
      quesData.questions[pageNo].html
    ).formelements;
    questDisplay.checksum = JSON.parse(
      quesData.questions[pageNo].html
    ).checksum.split("&amp")[pageNo];
    questDisplay.qubaid = quesData.attempt.uniqueid;
    questDisplay.qaid = JSON.parse(quesData.questions[pageNo].html).qaid;
    questDisplay.flagQuestion = flagQuestion;

    
  }

  console.log(quesData, "IND check1: full quesdata");

  return (
    <MainCard cardTitle={"Assessment Attempt"}>

      {console.log(quesData, "pppd")}
      {
        quesData === undefined ?
          <Loader /> :
          (quesData.hasOwnProperty("errorcode") ?
            <Box sx={{ p: 3 }}>{quesData.message}</Box> :
            <>
              <QuizQuestions qdata={questDisplay} handleChildAction={handleChildAction}/>
            </>
          )
      }

    </MainCard>
  );
}

export default AssessmentAttempt;
