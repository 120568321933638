import Grid from '@mui/material/Grid';
import './AssetsBox.scss'
const AssetsBox = (props) => {
    console.log(props.deletedID, "IND check: deleteID");
    //let displayStatus = props.uid === props.deletedID ? "none":"block";

    
    
    return (
        <Grid item xs={12} sm={5.8} md={2.8} >
            {props.children}
        </Grid>

    )
}

export default AssetsBox;