import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IoIosLock } from "react-icons/io";
import axios from "axios";
import { useState } from "react";
import Loader from "../../_Components/Utils/loader/Loader";
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";

const AssetContent = ({
  moduleValue,
  presentAssignment,
  imgName,
  checkActiveAssignments,
  clickOpenForm,
  courseID,
  department,
  // assesmentDeleted

}) => {
  console.log(moduleValue, "ind111");


  const nToken = localStorage.getItem("nToken");
  const token = localStorage.getItem("token");
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const [isAttempted, setIsAttempted] = useState(false);

  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({ title: "", desc: "" })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const ifQuizAttempted = (e, instanceID, moduleID, action) => {
    e.stopPropagation();

    console.log(instanceID, action);
    let ifAttemptedURL = `${process.env.REACT_APP_nodewsPrefix}/quizAttempt?quizID=${instanceID}`;

    axios.get(ifAttemptedURL,
      {
        headers: {
          Authorization: `Bearer ${nToken}`,
          'Content-Type': 'application/json'
        },
      }).then(response => {
        handleClickOpen();
        console.log(response.data, "Is att:", ifAttemptedURL);


        if (response.data.quizCount > 0) {
          // ASSESSMENT HAVE ATTEMPTS CAN NOT BE EDIT OR DELETED
          setIsAttempted(true);

          if (action === "delete") {
            // if delete button is clicked
            console.log("ASSESSMENT HAVE ATTEMPTS CAN NOT BE EDIT OR DELETED")
            setDialogData({ title: "Delete", desc: "Assessment attempted by student it can not be deleted" });
          } else {
            console.log("edit clicked ASSESSMENT HAVE ATTEMPTS CAN NOT BE EDIT OR DELETED")
            // if edit button is clicked
            setDialogData({ title: "Edit", desc: "Assessment attempted by student it can not be edited" });
          }
        } else {

          // ASSESSMENT NOT HAVE ATTEMPTS CAN BE EDIT OR DELETED
          console.log("not attempted", action);
          setIsAttempted(false);

          if (action === "delete") {
            // if delete button is clicked
            console.log("Are you sure you want to delete this assessment ?")
            setDialogData({ title: "Delete", desc: "Are you sure you want to delete this assessment ? ", instID:instanceID, moduleID:moduleID  });
          } else{
            handleClose();
            editAssessments(instanceID)
            console.log("go for edit")
          }
        }
      })
  }

  const deleteAssessments = (moduleID) => {
    setOpen(false);
    setIsDeleted(false);
    setIsDeleting(true);
    axios.post(`${process.env.REACT_APP_nodewsPrefix}/quizDelete`,
      {
        "url": `${process.env.REACT_APP_wsPrefix}/`,
        "token": token,
        "course_module_id": parseInt(moduleID),
      },
      {
        headers: {
          Authorization: `Bearer ${nToken}`,
          'Content-Type': 'application/json'
        },
      })
      .then(response => {
        setIsDeleting(false);
        setIsDeleted(true);
        console.log("deleted")
      })
      .catch((err) => {
        console.log(err.message)
        setIsDeleted("error");
        setTimeout(() => {
          setIsDeleted(false);
        }, 5000)
      });
  }

  const editAssessments = (instanceID) => {
    
    console.log("editAssessments", instanceID);
    navigate(`/editquiz?editing=true&moduleID=${moduleValue.instance}&courseid=${courseID}`);
  }

  if (isDeleting) {
    return (
      <Box className="assetsBoxes" onClick={clickOpenForm}><Loader /></Box>
    )
  } else if (isDeleted) {

    if (isDeleted === "error") {
      return (<Box className="assetsBoxes" onClick={clickOpenForm}>
        <CancelIcon />Not Deleted
      </Box>)
    }
    return (<Box className="assetsBoxes" sx={{cursor:"auto"}}>
      <DoneIcon />Deleted
    </Box>)
  } else {
    return (
      <>
        {console.log("dialogData",dialogData)}
        <Dialog
          open={open}
          onClose={handleClose}s
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dialogData.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogData.desc}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {
              isAttempted ? 
              <>
                <Button onClick={handleClose}>OK</Button>
              </>
              :
              <>
                <Button onClick={()=>deleteAssessments(dialogData.moduleID)}>Yes</Button>
                <Button onClick={handleClose} autoFocus>No</Button>
              </>
            }
          </DialogActions>
        </Dialog>

        <Box className="assetsBoxes" onClick={clickOpenForm}>
          {presentAssignment > 0 && !checkActiveAssignments && (
            <Box className="lockedBg">
              <IoIosLock size={30} />
            </Box>
          )}
          <Box
            className="titleFace"
            sx={{
              color: "textsColor.secColor",
            }}
          >
            {moduleValue.name}
          </Box>
          <Box className={`imgBox`}>
            {console.log(moduleValue.modname)}
            {moduleValue.modname === "quiz" ? <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/courseIcons/assessment.png`}
              className="assetOverlayImg"
              alt={imgName}
            /> : <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/courseIcons/${imgName}.png`}
              className="assetOverlayImg"
              alt={imgName}
            />}

          </Box>
          <Box className={`overlayBox ${moduleValue.modname === "quiz" && "quizOverlay"}`}>
            {console.log(moduleValue)}

            {moduleValue.modname === "quiz" && (department === "pedagogy" || department === "admin") &&
              <>
                <Box className="delete" onClick={e => { ifQuizAttempted(e, moduleValue.instance, moduleValue.id, "delete") }}><Tooltip title="Delete"><DeleteForeverIcon /></Tooltip></Box>
                <Box className="edit" onClick={e => { ifQuizAttempted(e, moduleValue.instance, moduleValue.id, "edit") }}><Tooltip title="Edit"><EditIcon /></Tooltip></Box>
              </>
            }
            <Box className="topIcon">{moduleValue.name}</Box>
            {presentAssignment > 0 && !checkActiveAssignments && (
              <Typography className="dataInfo">
                Locked until the assignment is completed.
              </Typography>
            )}
          </Box>

        </Box>
      </>
    );
  }

};

export default AssetContent;
